export const dataPointInteractions = {
  order: 2,
  name: "Datapoint interactions",
  keywords:
    "unlock declassify publish share stake edit remove task note link contact center map",
  subcategory: "Intro to datapoints",
  markdown: `# Datapoint interactions

  {% inlineRouterLink articleId="what-is-a-datapoint?" %}Datapoints{% /inlineRouterLink %} offer numerous interactions through a menu that appears when you {% inlineAppIcon iconName="cursor" /%}left click. Read on to learn about a variety of helpful actions.

  ![Datapoint menu screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Edit & remove

  Use the menu to {% inlineRouterLink %}edit{% /inlineRouterLink %} and {% inlineRouterLink %}remove{% /inlineRouterLink %} existing datapoints.

  {% callout type="note" %}
  **Note:** To edit a datapoint, you must be viewing it in the same part of Tower Hunt where it was created (ex. {% inlineRouterLink articleId="details-tab" %}Details tab{% /inlineRouterLink %}, {% inlineRouterLink articleId="deal-hub" %}Deal Hub{% /inlineRouterLink %}).
  {% /callout %}

  ## Unlock

  The menu makes it easy to {% inlineRouterLink articleId="unlock-datapoints" %}price and unlock datapoints{% /inlineRouterLink %} that you do not yet have access to.

  ![Datapoint menu unlock screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## View contributors

  See how many different {% inlineRouterLink %}users contributed{% /inlineRouterLink %} to the data you are using, as well as their {% inlineRouterLink articleId="understand-reputation" %}reputation levels{% /inlineRouterLink %}, directly from the menu.

  ## View citations

  {% inlineRouterLink articleId="add-a-citation" %}Citations{% /inlineRouterLink %} provide evidence to support the accuracy and credibility of datapoints. Use the menu to view and add citations without leaving your current view.

  ## Declassify

  If you are interested in {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}earning revenue{% /inlineRouterLink %}, you can {% inlineRouterLink articleId="declassify-datapoints" %}declassify a Safezone datapoint{% /inlineRouterLink %} from the menu.

  ## View Safezone

  If you're looking at an individual {% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} datapoint and realize you want to take action on multiple datapoints at once, you can view your Safezone.

  ## Publish

  When you are ready to put a datapoint into the {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}validation pipeline{% /inlineRouterLink %}, you can publish it from the menu.

  ## Share

  The menu makes it easy to {% inlineRouterLink articleId="share-datapoints" %}add Safezone datapoints to the Sharing Stage{% /inlineRouterLink %}.

  ## Create tasks

  Use the menu to quickly {% inlineRouterLink articleId="create-a-task" %}create a task{% /inlineRouterLink %} linked to the datapoint without leaving your current view.

  ## View related tasks

  When a datapoint has one or more linked tasks, you can quickly {% inlineRouterLink articleId="view-tasks-linked-to-a-datapoint" %}view them all{% /inlineRouterLink %} from the menu.

  ## View and add notes

  Use the menu to quickly {% inlineRouterLink articleId="add-notes-to-datapoints" %}view and add notes{% /inlineRouterLink %} related to the datapoint without leaving your current view.

  ## Center property on map

  When viewing {% inlineRouterLink articleId="stay-organized-with-tasks" %}tasks{% /inlineRouterLink %} and {% inlineRouterLink articleId="survey-properties" %}surveys{% /inlineRouterLink %}, sometimes you want to quickly see where a property is on the map. You can do this from the menu.

  ![Datapoint menu center map screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## View property diagram

  When viewing property-related datapoints, you can navigate to the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} from the menu.

  ## View investment details

  When viewing investment-related datapoints, you can open the related {% inlineRouterLink articleId="the-role-of-investments" %}investment{% /inlineRouterLink %} in {% inlineRouterLink articleId="deal-hub" %}Deal Hub{% /inlineRouterLink %} from the menu.

  ## View company details

  When viewing company-related datapoints, you can navigate to the {% inlineRouterLink %}company details page{% /inlineRouterLink %} from the menu.

  ## View contact details

  When viewing contact-related datapoints, you can navigate to the {% inlineRouterLink %}contact details page{% /inlineRouterLink %} from the menu.

  ## Calculation options

  {% inlineRouterLink articleId="perform-calculations-on-datapoints" %}Calculation datapoints{% /inlineRouterLink %} can include options that influence the output. You can set those options from the menu.`,
};
